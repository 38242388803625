import React, { useEffect, useState } from 'react'
import './App.css'
import QRCode from 'react-qr-code'

async function getDeepLink(id: string): Promise<{ deepLink: string }> {
  const req = await fetch(`https://api.fifire.xyz/v1/user/deep-link?id=${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return req.json()
}

function App() {
  const [deepLink, setDeepLink] = React.useState<string | null>(null)
  const [error, setError] = React.useState<string | null>(null)
  const [readTheRules, setReadTheRules] = useState(false)
  const params = new URLSearchParams(window.location.search)
  const id = params.get('id') as string
  const isInstall = window.location.pathname === '/install' && Boolean(id)

  useEffect(() => {
    if (isInstall) {
      getDeepLink(id)
        .then(data => {
          setDeepLink(data.deepLink)
        })
        .catch(e => setError(e.message))
    }
  }, [isInstall, id])

  return (
    <div className="App">
      <header className="App-header">
        {error && <h1>Error: {error}</h1>}
        {!isInstall && (
          <>
            <div>Hey</div>
            <a href="https://warpcast.com/fifire">@fifire at Warpcast</a>
          </>
        )}
        {isInstall && !deepLink && <h1>Loading...</h1>}
        {isInstall &&
          deepLink &&
          (readTheRules ? (
            <>
              <h3>Scan it</h3>
              <QRCode value={deepLink!} />
              <p>
                <a className="App-link" href={deepLink!}>
                  Click if you on mobile
                </a>
              </p>
            </>
          ) : (
            <div style={{ margin: '0 20pt 0 20pt' }}>
              <h1>Read it</h1>
              <p>
                • By connecting to Warpcast, <strong>FiFire will send a qualifying cast</strong> on your behalf to
                ensure you receive FIRE points.
              </p>
              <p>
                • <strong>FiFire will subscribe</strong> you to the @fifire account to receive the latest updates.
              </p>
              <p>
                • Every time you give a Tip for a Cast, <strong>FiFire will post</strong> a message under that Cast.
              </p>
              <p>
                • We use Pinata services and they are <strong>NOT related</strong> to the content of the publications.
              </p>
              <button className="button" onClick={() => setReadTheRules(true)}>
                Agree
              </button>
            </div>
          ))}
      </header>
    </div>
  )
}

export default App
